export const drawJarHeight = (currentStarterHeight) => {
  const heightPercentage = 1 - (122 - currentStarterHeight) / 122;
  // 23 is "Full" and 92 is "Empty"
  const svgVerticalPathDistance = 23 + heightPercentage * (92 - 23);

  document.querySelectorAll(".jar-height").forEach((e) => {
    const pathData = e.getPathData();
    e.setPathData(
      pathData.map((d, i) => {
        if (i === 1) {
          return { type: "v", values: [svgVerticalPathDistance] };
        } else {
          return d;
        }
      })
    );
  });
};
