import { drawJarHeight } from "./starterGraph/drawJarHeight";

const onDocumentReady = () => {
  const heightJarElement = document.querySelector("#most-recent-vital-height");
  if (heightJarElement) {
    const currentStarterHeight = heightJarElement.dataset.height;
    drawJarHeight(currentStarterHeight);
  }
};
$(document).ready(onDocumentReady);
